import { Component } from '@angular/core';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Perfil', url: '/account/perfil', icon: 'person-circle' },
  ];
  public labels = [];
  constructor() {
    defineCustomElements(window);
  }
}

export interface User {
  name?: string;
  last?: string;
  user?: string;
  id?: string;
  phone?: string;
  mail?: string;
}