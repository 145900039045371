import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //console.log('AuthGuard')
    if ((sessionStorage.getItem('2L_DATA') === null) || (sessionStorage.getItem('2L_DATA') == undefined)) {
      this.router.navigate(['account']);
      return false;
    }

    return true;
  }

}